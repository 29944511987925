import Vue from 'vue'
import App from '@/App'
import router from '@/router'                 // api: https://github.com/vuejs/vue-router
import store from '@/store'                   // api: https://github.com/vuejs/vuex
import VueCookie from 'vue-cookie'            // api: https://github.com/alfhen/vue-cookie
import '@/element-ui'                         // api: https://github.com/ElemeFE/element
import '@/icons'                              // api: http://www.iconfont.cn/
import '@/element-ui-theme'
import '@/assets/less'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import '@/../static/config/index.js'
import '@/utils/indexApi.js'
import validate from '@/utils/validate.js'
import cloneDeep from 'lodash/cloneDeep'
import commonUI from '@/views/common/components'
import md5 from 'js-md5'
import echarts from 'echarts'
// import plTable from 'pl-table';
// import 'pl-table/themes/index.css' // 引入样式（必须引入)，vuecli3.0不需要配置，cli2.0请查看webpack是否配置了url-loader对woff，ttf文件的引用,不配置会报错哦
// import 'pl-table/themes/plTableStyle.css'

//
import Print from '@/utils/print' // 打印
Vue.use(Print)

Vue.use(VueCookie)
Vue.use(commonUI)
// Vue.use(plTable)

Vue.config.productionTip = false
Vue.prototype._Valid = validate
Vue.prototype.$md5 = md5
// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$echarts = echarts
// 保存整站vuex本地储存初始状态
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
