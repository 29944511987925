/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,6})?$/.test(s)
  // return /^[0-9]{3,4}\\-[0-9]{3,8}\\-[0-9]{1,8}$/.test(s)
  // return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}
/**
 * URL地址
 * @param {*} s
 */
export function isCard (s) {
  let regExp = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
  return regExp.test(s)
}

export default {
  /*
  初始化校验
  arg为需要校验项的数组集合，需要优先校验项放在最后
  */
  canNullinit (arg) {
    let set = this.getNullSetting()
    set.validator = (rule, value, callback) => {
      let i = arg.length, err
      while (i--) {
        err = this[arg[i]](value)
        if (err) break
      }
      if (err) {
        rule.message = err
        callback(new Error(''))
      } else {
        callback()
      }
    }
    return [Object.assign({}, set)]
  },
  getNullSetting () {
    return {
      tigger: 'change',
      validator () {}
    }
  },
  init (arg) {
    let set = this.getSetting()
    set.validator = (rule, value, callback) => {
      let i = arg.length, err
      while (i--) {
        err = this[arg[i]](value)
        if (err) break
      }
      if (err) {
        rule.message = err
        callback(new Error(''))
      } else {
        callback()
      }
    }
    return [Object.assign({}, set)];
  },
  getSetting () {
    return {
      message: '该项不能为空.',
      required: true,
      tigger: 'change',
      validator () {}
    }
  },
  // 为空校验
  null (value) {
    let regExp = /^\s*$/g;
    if (regExp.test(value) || 'undefined' == typeof(value) || null == value) {
      return '该项不能为空'
    }
  },
  canNull (value) {
    if (!value) {
      return false
    }
  },
  /**
   * 电话号码
   * @param {*} s
   */
  isPhone (s) {
    return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
  },
  /**
   * 手机号码
   * @param {*} s
   */
  isMobile (s) {
    return /^1[0-9]{10}$/.test(s)
  },
  /**
   * 电话号码或手机号码
   * @param {*} value
   */
  validateMobile (value) {
    if (!value) {
      return false
    } else if (!this.isMobile(value) && !this.isPhone(value)) {
      return !this.isMobile(value) && !this.isPhone(value) && '联系电话输入有误'
    } else {
      return !this.isMobile(value) && !this.isPhone(value)
    }
  },
  /**
 * 邮箱
 * @param {*} s
 */
  isEmail (s) {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
  },
  // 车牌校验
  carNumber (value) {
    let regExp = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1,2}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
    return !regExp.test(value) && '车牌格式有误';
  },
  // 包含0的正整数校验
  int (value) {
    let regExp = /^([1-9]\d*|0{1})$/
    return !regExp.test(value) && '该项为正整数';
  },
  // 不包含0的正整数
  intNoZero (value) {
    let regExp = /^([1-9]\d*)$/
    return !regExp.test(value) && '该项为正整数';
  },
  // 最多2位小数的价格校验
  price (value) {
    let regExp = /^(0{1}|[1-9]\d*)(?:\.\d{1,2})?$/
    return !regExp.test(value) && '价格最多保留2位小数'
  },
  // 最多2位小数的价格校验
  nullOrPrice (value) {
    let regExp = /^(0{1}|[1-9]\d*)(?:\.\d{1,2})?$/
    if (!value) {
      return false
    } else {
      return !regExp.test(value) && '价格最多保留2位小数'
    }
  },
  // 身份证验证
  idCard (s) {
    let regExp = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
    return !regExp.test(s) && '身份证号码格式错误'
  }
}
