import AfTableColumn from './AFTableColumn'
import AlUpload from './AlUpload'
import AlUploadCard from './AlUploadCard'
import aLink from './link'
export default {
  install (Vue) {
    Vue.component(AfTableColumn.name, AfTableColumn)
    Vue.component(AlUploadCard.name, AlUploadCard)
    Vue.component(AlUpload.name, AlUpload)
    Vue.component(aLink.name, aLink)
  }
}
