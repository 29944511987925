import Vue from 'vue'

import { isAuth, newAuth, orderType, yesNo, vehicleType, motorcadeType, entranceType, dictConvert, manyDictConvert, dateFormatter, cardType, getValue, numFilter, realType, marketTrackType } from './index'
import { showButton, getSpanArr, arraySpanMethod, renderHeader, getDefaultValue } from './common'

Vue.prototype.isAuth = isAuth // 权限方法
Vue.prototype.newAuth = newAuth // 权限方法
Vue.prototype.orderType = orderType // 订单类型
Vue.prototype.yesNo = yesNo // 是否
Vue.prototype.vehicleType = vehicleType // 车型
Vue.prototype.manyDictConvert = manyDictConvert // 多个字典类型
Vue.prototype.dictConvert = dictConvert // 单个字典转换
Vue.prototype.dateFormatter = dateFormatter // 日期格式转换
Vue.prototype.motorcadeType = motorcadeType // 所属车队类型
Vue.prototype.entranceType = entranceType // 所属车队类型
Vue.prototype.cardType = cardType // 所属车队类型
Vue.prototype.realType = realType // 认证类型
Vue.prototype.marketTrackType = marketTrackType // 客户反馈类型

Vue.prototype.showButton = showButton // 按钮权限
Vue.prototype.getSpanArr = getSpanArr // 合并
Vue.prototype.arraySpanMethod = arraySpanMethod // 合并
Vue.prototype.renderHeader = renderHeader //
Vue.prototype.getDefaultValue = getDefaultValue // 转换数字
Vue.prototype.getValue = getValue
Vue.prototype.numFilter = numFilter
