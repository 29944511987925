import Vue from 'vue'
import router from '@/router'
import store from '@/store'

/**
 * 获取uuid
 */
export function getUUID () {
  let uuId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
  return uuId.replace('-', '')
}

/**
 * 日期格式化
 * @param value
 * @param fmt
 * @returns 返回fmt格式字符串
 */
export function dateFormatter (value, fmt) {
  let $this = new Date(value)
  let o = {
    'M+': $this.getMonth() + 1,
    'd+': $this.getDate(),
    'h+': $this.getHours(),
    'm+': $this.getMinutes(),
    's+': $this.getSeconds(),
    'q+': Math.floor(($this.getMonth() + 3) / 3),
    'S': $this.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ($this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
export function getValue (value) {
  if (value == 0) {
    return ''
  } else {
    return value
  }
}
// 截取当前数据到小数点后两位
export function numFilter (value) {
  if (value === '') {
    return value
  }
  return parseFloat(parseFloat(value).toFixed(2))
}
// 订单类型
export function orderType (row, column, cellValue, index) {
  let val = ''
  store.state.common.dictTypeMap.orderType.forEach(item => {
    if (item.id === cellValue) {
      val = item.name
    }
  })
  return val
}

// 认证类型
export function realType (row, column, cellValue, index) {
  let val = ''
  store.state.common.dictTypeMap.customer_register_type.forEach(item => {
    if (item.id === cellValue) {
      val = item.name
    }
  })
  return val
}

export function vehicleType (row, column, cellValue, index) {
}

export function marketTrackType (row, column, cellValue, index) {
}

// 是否
export function cardType (row, column, cellValue) {
  let val = ''
  store.state.common.dictTypeMap.card_type.forEach(item => {
    if (item.id === cellValue) {
      val = item.name
    }
  })
  return val
}
// 是否
export function yesNo (row, column, cellValue) {
  let val = ''
  let number = parseInt(cellValue)
  store.state.common.dictTypeMap.yes_no.forEach(item => {
    if (item.id === number) {
      val = item.name
    }
  })
  return val
}
// 所属车队类型
export function motorcadeType (row, column, cellValue) {
  let val = ''
  store.state.common.dictTypeMap.motorcade_type.forEach(item => {
    if (item.id === cellValue) {
      val = item.name
    }
  })
  return val
}
// 所属车队类型
export function entranceType (row, column, cellValue) {
  let val = ''
  if (Array.isArray(store.state.common.dictTypeMap.entrance_type)) {
    store.state.common.dictTypeMap.entrance_type.forEach(item => {
      if (item.id === cellValue) {
        val = item.name
      }
    })
  }
  return val
}

// 单个字典转换
export function dictConvert (row, column, cellValue) {
  return store.state.common.dictInfoMap[cellValue]
}

// 多个字典类型
export function manyDictConvert (row, column, cellValue) {
  if (!cellValue) {
    return null
  }
  let infoMap = store.state.common.dictInfoMap
  let values = cellValue.split(',')
  let value = ''
  for (let i = 0; i < values.length; i++) {
    value += ',' + infoMap[values[i]]
  }
  return value.substring(1)
}
/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth (key) {
  return true
  // return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}
/**
 * 是否有权限
 * @param {*} key
 */
export function newAuth (key) {
  // return true
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  Vue.cookie.delete('TmsToken')
  store.commit('resetStore')
  router.options.isAddDynamicMenuRoutes = false
}
