
import store from '@/store'
let updateStatusArray = [0, 1, 10, 11, 12]
let deleteStatusArray = [0, 9, 10, 11, 12]
let cancelStatusArray = [1, 2, 13]
let commitStatusArray = [0, 10, 11, 12]
let noSpanPropertyArray = ['factoryName', 'fullAddress', 'route', 'contactName', 'contactPhone', 'takeRemark', 'outDriveTime', 'arrivedTime', 'loadGoodsTime',
  'giveAddressInfo.factoryName', 'giveAddressInfo.fullAddress', 'giveAddressInfo.contactName', 'giveAddressInfo.contactPhone', 'giveAddressInfo.route']
let spanArr = []
export function showButton (row, type, auth) {
  let statusArray = []
  // let auth = null
  switch (type) {
    case 'commit':
      statusArray = commitStatusArray
      // auth = 'order:customer:commit'
      break
    case 'update':
      statusArray = updateStatusArray
      // auth = 'order:customer:update'
      break
    case 'delete':
      statusArray = deleteStatusArray
      // auth = 'order:customer:delete'
      break
    case 'cancel':
      statusArray = cancelStatusArray
      // auth = 'order:customer:cancel'
      break
    default:
      break
  }
  if (auth) {
    // 无权限
    if (!this.newAuth(auth)) {
      return false
    }
  }
  return statusArray.indexOf(row.orderInfo.status) > -1
}

export function getSpanArr (data) {
  spanArr = []
  for (let i = 0; i < data.length; i++) {
    if (i === 0) {
      spanArr.push(1)
      this.pos = 0
    } else {
      // 判断当前元素与上一个元素是否相同
      if (data[i].orderInfo.id === data[i - 1].orderInfo.id) {
        spanArr[this.pos] += 1
        spanArr.push(0)
      } else {
        spanArr.push(1)
        this.pos = i
      }
    }
  }
}

export function arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
  let property = column.property
  // 状态合并校验
  if (property === 'status') {
    if (store.state.common.spanStatusArray.indexOf(row.orderInfo.status) > -1) {
      const _row = spanArr[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col
      }
    }
  } else if (noSpanPropertyArray.indexOf(property) === -1) {
    const _row = spanArr[rowIndex]
    const _col = _row > 0 ? 1 : 0
    return {
      rowspan: _row,
      colspan: _col
    }
  }
}

// 表头部分样式
export function renderHeader (h, { column, $index }) {
  return h('div', {
    attrs: {
      class: 'smallRow'
    },
    // domProps: {
    //   innerHTML: '<span class="red">*</span>' + column.label
    // }
    domProps: {
      innerHTML: column.label
    }
  })
}

export function getDefaultValue (value, defaultValue) {
  if (value === undefined || value === null) {
    return defaultValue
  }
  let number = Number(value)
  if (number === undefined || number === null) {
    return defaultValue
  }
  if (isNaN(number)) {
    return 0
  } else {
    return number
  }
}
