<template>
  <div>
    <el-upload
      ref="upload"
      :action="fileUrl"
      :data="{businessType: businessType}"
      :headers="{'access_token': $cookie.get('TmsToken')}"
      :limit="limit"
      :on-exceed="limitExceed"
      :file-list="allFileList"
      :show-file-list="true"
      multiple
      :on-error="errorFileList"
      :before-upload="beforeAvatarUpload"
      :on-success="saveFileList">
      <el-button size="mini" type="primary" v-if="!isView">点击上传</el-button>
      <!-- <div slot="tip" class="el-upload__tip">仅支持上传jpg、png、Excel、pdf格式文件</div> -->
      <div slot="file" slot-scope="{file}">
        <!-- <span><a :href="file.url" target="_Blank">{{file.name}}</a></span> -->
        <span @click="handleDownload(file)" style="color:blue">
          {{file.name}}
        </span>
        <span class="el-upload-list__item-actions" v-if="!isView">
          <span class="el-upload-list__item-preview" @click="removeFileList(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
        <!-- <span class="el-upload-list__item-actions" v-else>
          <span class="el-upload-list__item-preview" @click="handleDownload(file)">
            <i class="el-icon-download"></i>
          </span>
        </span> -->
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  data () {
    return {
      allFileList: []
    }
  },
  name: 'al-upload',
  props: ['fileData', 'fileUrl', 'limit', 'businessType', 'type', 'isView', 'format', 'notShow'],
  mounted () {
    let that = this
    if (that.fileData) {
      this.allFileList = JSON.parse(JSON.stringify(this.fileData))
    }
    // this.allFileList = JSON.parse(JSON.stringify(this.fileData))
  },
  watch: {
    fileData (data) {
      let that = this
      if (that.fileData) {
        this.allFileList = JSON.parse(JSON.stringify(this.fileData))
      }
      // this.allFileList = JSON.parse(JSON.stringify(this.fileData))
    }
  },
  methods: {
    limitExceed (data, files) {
      if (files.length === this.limit) {
        this.$message.error(`文件上传限制为${this.limit}个文件`)
      }
    },
    downLoadXls (data, filename) {
      // var blob = new Blob([data], {type: 'application/vnd.ms-excel'})接收的是blob，若接收的是文件流，需要转化一下
      if (typeof window.chrome !== 'undefined') {
        // Chrome version
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = filename
        link.click()
      } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE version
        var blob = new Blob([data], { type: 'application/force-download' })
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Firefox version
        var file = new File([data], filename, { type: 'application/force-download' })
        window.open(URL.createObjectURL(file))
      }
    },
    handleDownload (file) {
      this.$http({
        url: this.$http.adornUrl(`/upload/download/stream/${file.id}`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        var link = document.createElement('a')
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = file.name
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      if (response.code === 100000) {
      } else {
        file = {}
      }
      this.fileData.push({ id: resData.id, name: file.name, status: file.status, uid: file.uid, businessType: resData.businessType })
      this.$emit('getFileData', {
        fileList: this.fileData,
        type: this.type
      })
    },
    beforeAvatarUpload (response, file) {
      if (this.format === 'img') {
        if (response.type === this.$store.state.common.enclosureDto.jpegType ||
           response.type === this.$store.state.common.enclosureDto.pngType) {
          return true
        }
        this.$message.error('仅支持上传图片格式文件')
        return false
      }
      // this.allFileList.push({ id: file.id, name: file.name, status: file.status, uid: file.uid, businessType: file.businessType })
    },
    errorFileList (response, file) {
    },
    // 删除文件信息
    removeFileList (file) {
      this.$confirm('确定进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let fileId = file.id
        let fileList = this.fileData
        let allFileList = this.allFileList
        // 从集合删除
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].id === fileId) {
            fileList.splice(i, 1)
            allFileList.splice(i, 1)
            break
          }
        }
        this.$emit('getFileData', {
          fileList: fileList,
          type: this.type
        })
      }).catch(() => {})
    }
  }
}
</script>
