import da from "element-ui/src/locale/lang/da";

export default {
  namespaced: true,
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 导航条, 布局风格, defalut(默认) / inverse(反向)
    navbarLayoutType: 'default',
    // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
    sidebarLayoutSkin: 'light',
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    menuList: [],
    menuActiveName: '',
    // 内容, 是否需要刷新
    contentIsNeedRefresh: false,
    // 主入口标签页
    mainTabs: [],
    menusSelectLi: '',
    wayBillType: '',  // 订单类型
    audited: '',  // 新委派
    getGiveAreaList: [],
    mainTabsActiveName: '',
    notesCount: 0,  // 通知数量
    memorandum: false,  // 是否显示弹框
    memorandumList: [], // 弹框内容
    enclosureDto: { // 附件
      uploadUrl: '',
      accessToken: '',
      show: false,
      download: false,
      delete: true,
      type: 6001,
      jpegType: 'image/jpeg',
      pngType: 'image/png',
      excel13Type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      excel07Type: 'application/vnd.ms-excel',
      pdfType: 'application/pdf'
    },
    // 数据字典
    dictTypeMap: [],
    dictInfoMap: [],
    spanStatusArray: [0, 1, 2, 3, 7, 8, 9, 10, 11, 12], // 状态
    pickerOptions: {
      shortcuts: [{
        text: '最近一周',
        onClick (picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近一个月',
        onClick (picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近三个月',
        onClick (picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }]
    },
    areaList: []
  },
  mutations: {
    updateDictMapByKey (state, data) {
      let key = ''
      for (let dataKey in data) {
        key = dataKey
        break
      }
      let value = data[key]
      state.dictInfoMap = []
      state.dictTypeMap[key] = value
      for (let key in state.dictTypeMap) {
        switch (key) {
          case 'status':
          case 'abnormalStatus':
          case 'yes_no':
          case 'orderStatusMap':
          case 'orderType':
          case 'dispatchStatus':
          case 'planStatus':
            continue
          default:
            break
        }
        for (let i = 0; i < state.dictTypeMap[key].length; i++) {
          let info = state.dictTypeMap[key][i]
          state.dictInfoMap[info.id] = info.name
        }
      }
    },
    updateDictTypeMap (state, typeMap) {
      state.dictInfoMap = []
      for (let key in typeMap) {
        switch (key) {
          case 'status':
          case 'abnormalStatus':
          case 'yes_no':
          case 'orderStatusMap':
          case 'orderType':
          case 'dispatchStatus':
          case 'planStatus':
            continue
          default:
            break
        }
        for (let i = 0; i < typeMap[key].length; i++) {
          let info = typeMap[key][i]
          state.dictInfoMap[info.id] = info.name
        }
      }
      state.dictTypeMap = typeMap
    },
    updateDictInfoMap (state, infoMap) {
      state.dictInfoMap = infoMap
    },
    updateDocumentClientHeight (state, height) {
      state.documentClientHeight = height
    },
    updateNavbarLayoutType (state, type) {
      state.navbarLayoutType = type
    },
    updateSidebarLayoutSkin (state, skin) {
      state.sidebarLayoutSkin = skin
    },
    updateSidebarFold (state, fold) {
      state.sidebarFold = fold
    },
    updateMenuList (state, list) {
      state.menuList = list
    },
    updateMenuActiveName (state, name) {
      state.menuActiveName = name
    },
    updateContentIsNeedRefresh (state, status) {
      state.contentIsNeedRefresh = status
    },
    updateMainTabs (state, tabs) {
      state.mainTabs = tabs
    },
    updateMainTabsActiveName (state, name) {
      state.mainTabsActiveName = name
    },
    updateWayBillType (state, name) {
      state.wayBillType = name
    },
    updateAudited (state, name) {
      state.audited = name // 新委派
    },
    updateMenusSelectLi (state, name) {
      state.menusSelectLi = name // 顶部tab页签
    },
    updateMemorandum (state, name) {
      state.memorandum = name // 更新备忘录状态
    },
    updateMemorandumList (state, list) {
      state.memorandumList = list
    },
    updateNotesCount (state, name) {
      state.notesCount = name
    }
  }
}
