/**
 * 开发环境
 */
(function () {
  window.SITE_CONFIG = {}

  // api接口请求地址
  window.SITE_CONFIG['baseUrl'] = '/rtd/tms/v1'

  // cdn地址 = 域名 + 版本号
  window.SITE_CONFIG['domain'] = './'
  // 版本号(年月日时分)
  window.SITE_CONFIG['version'] = '3.0.0'
  window.SITE_CONFIG['cdnUrl'] = window.SITE_CONFIG.domain + window.SITE_CONFIG.version
})()
